var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon-wrap",class:{ ripple: 'ripple' },style:({ background: _vm.background })},[_c('svg',{staticClass:"icon",class:[_vm.iconClass],style:({
      width: _vm.iconSize,
      height: _vm.iconSize,
      color: _vm.color,
      background: _vm.background,
      ..._vm.extraStyle,
    })},[(_vm.icon === 'whatsapp')?_c('use',{attrs:{"href":require("../../assets/icons/sprite.svg") + "#whatsapp"}}):_vm._e(),(_vm.icon === 'telegram')?_c('use',{attrs:{"href":require("../../assets/icons/sprite.svg") + "#telegram"}}):_vm._e(),(_vm.icon === 'instagram')?_c('use',{attrs:{"href":require("../../assets/icons/sprite.svg") + "#instagram"}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }