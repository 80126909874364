<script>
import {mapGetters} from "vuex";

export default {
  name: "HeaderMobileMenu.vue",
  computed: {
    ...mapGetters({
      menuVisible: "menuVisible"
    })
  },
  methods: {
    input() {
      this.$store.commit("set", ["menuVisible", !this.menuVisible]);
    }
  }
}
</script>

<template>
  <div class="header-burger">
    <input
      type="checkbox"
      id="burger-checkbox"
      class="burger-checkbox"
      v-model="menuVisible"
      @input="input"
    >
    <label class="burger" for="burger-checkbox"></label>
  </div>
</template>

<style scoped lang="scss">
.burger-checkbox {
  position: absolute;
  visibility: hidden;
}
.burger {
  cursor: pointer;
  display: block;
  position: relative;
  border: none;
  background: transparent;
  width: 40px;
  height: 26px;
  margin: 30px 0;
}
.burger::before,
.burger::after {
  content: '';
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: #000;
}
.burger::before {
  top: 0;
  box-shadow: 0 11px 0 #000;
  transition: box-shadow .3s .15s, top .3s .15s, transform .3s;
}
.burger::after {
  bottom: 0;
  transition: bottom .3s .15s, transform .3s;
}
.burger-checkbox:checked + .burger::before {
  top: 11px;
  transform: rotate(45deg);
  box-shadow: 0 6px 0 rgba(0,0,0,0);
  transition: box-shadow .15s, top .3s, transform .3s .15s;
}
.burger-checkbox:checked + .burger::after {
  bottom: 11px;
  transform: rotate(-45deg);
  transition: bottom .3s, transform .3s .15s;
}
</style>