var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"button-component",class:[
    { 'load': _vm.load },
    { 'shimmer': _vm.shimmer }
  ],style:({ 
    fontSize: _vm.fontSize,
    fontWeight: _vm.fontWeight,
    width: _vm.display === 'block' ? '100%' : _vm.width,
    ..._vm.extraStyle
  }),attrs:{"type":_vm.type,"disabled":_vm.load},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default"),_c('span',{staticClass:"button-component-text",style:({ marginLeft: _vm.hasSlot ? '8px' : '' })},[_vm._v(_vm._s(_vm.btnName))])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }